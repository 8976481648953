import React, {Component} from "react";
import { connect } from "react-redux";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Button
} from 'reactstrap';

import {Auth} from 'aws-amplify';
import {setUser, setAuthorizationToken} from '../auth/authSlice';
import {addLogEvent} from "../logging/loggingSlice";
import { apiCall } from "../../services/api";
import { displayLoader } from "../../store/actions/loading";

import LinkSection from "./LinkSection";
import AddSectionModal from "./AddSectionModal";
import AddLinkModal from "./AddLinkModal";

import './links.css';

class Links extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 'work',
            addSectionModalOpen: false,
            newSection: {
                group:'',
                name:''
            },
            addLinkModalOpen: false,
            newLink: {
                group:'',
                name:'',
                href:''
            },
            work_links: [],
            personal_links: []
        }
        this.selectTab = this.selectTab.bind(this)
        this.openAddSectionModal = this.openAddSectionModal.bind(this)
        this.closeAddSectionModal = this.closeAddSectionModal.bind(this)
        this.handleAddSectionInput = this.handleAddSectionInput.bind(this)
        this.handleAddSectionSubmit = this.handleAddSectionSubmit.bind(this)

        this.openAddLinkModal = this.openAddLinkModal.bind(this)
        this.closeAddLinkModal = this.closeAddLinkModal.bind(this)
        this.handleAddLinkInput = this.handleAddLinkInput.bind(this)
        this.handleAddLinkSubmit = this.handleAddLinkSubmit.bind(this)
    }

    componentDidMount(){
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                const { attributes } = user;
                this.props.setUser(attributes)
                setAuthorizationToken(jwt);
            })
            .catch(err => {console.log(err)});

        this.props.displayLoader(true)
        apiCall('get', '/links').then(
            data => {
                this.props.addLogEvent({
                  eventType: 'GetLinks',
                  status: 'success',
                  details: '',
                })
                this.setState(
                    data
                )
                this.props.displayLoader(false)
            }
        ).catch(err=> {
            this.props.addLogEvent({
              eventType: 'GetLinks',
              status: 'error',
              details: err.message,
            })
        }) 
    }

    saveLinks = e => {
        let data = {
            work_links: this.state.work_links,
            personal_links: this.state.personal_links
        }

        apiCall('post', '/links', data).then(
            this.props.addLogEvent({
              eventType: 'updateLinks',
              status: 'success',
              details: '',
            })
        ).catch(err => {
            this.props.addLogEvent({
              eventType: 'updateLinks',
              status: 'error',
              details: err.message,
            })
        })
    }

    selectTab = e => {
        this.setState({
            activeTab: e.target.name
        })
    }

    openAddSectionModal = e => {
        this.setState({
            addSectionModalOpen: true,
            newSection: {
                group: e.target.name,
                name: ''
            }
        })
    }

    closeAddSectionModal = e => {
        this.setState({
            addSectionModalOpen: false,
            newSection: {
                group: '',
                name: ''
            }
        })
    }

    handleAddSectionInput = e => {
        this.setState({
            newSection: {
                ...this.state.newSection,
                name: e.target.value,
                
            }
        })
    }

    handleAddSectionSubmit = e => {
        let {newSection} = this.state;
        let links = []

        if (newSection.group === 'work') {
            links = [...this.state.work_links]
            
            links.push({
                category: newSection.name,
                links:[]
            })

            this.setState({
                work_links: links,
                addSectionModalOpen: false,
                newSection: {
                    group: '',
                    name: ''
                }
            }, () => {
                this.saveLinks()
            })

            }
        else {
            links = [...this.state.personal_links]
            
            links.push({
                category: newSection.name,
                links:[]
            })

            this.setState({
                personal_links: links,
                addSectionModalOpen: false,
                newSection: {
                    group: '',
                    name: ''
                }
            }, () => {
                this.saveLinks()
            })
        }

    }

    openAddLinkModal = e => {
        this.setState({
            addLinkModalOpen: true,
            newLink: {
                group: e.target.name,
                name: '',
                href: ''
            }
        })
    }

    closeAddLinkModal = e => {
        this.setState({
            addLinkModalOpen: false,
            newLink: {
                group: '',
                name: '',
                href: ''
            }
        })
    }

    handleAddLinkInput = e => {
        let {newLink} = this.state;
        newLink[e.target.name] = e.target.value
        this.setState({
            newLink
        })
    }    

    handleAddLinkSubmit = e => {
        let {newLink} = this.state;
        let { work_links, personal_links } = this.state
        let type = newLink.group.split('_')[0]
        let category = newLink.group.split('_')[1]
        let index

        if (type === 'work') {
            index = work_links.findIndex(x => x.category === category);
            
            work_links[index].links.push({
                name: newLink.name,
                url: newLink.href
            })

            this.setState({
                work_links
            }, () => {
                this.saveLinks()
            })

        } else {
            index = personal_links.findIndex(x => x.category === category);
            
            personal_links[index].links.push({
                name: newLink.name,
                url: newLink.href
            })

            this.setState({
                personal_links
            }, () => {
                this.saveLinks()
            })
        }

        this.setState({
            addLinkModalOpen: false,
            newLink: {
                group: '',
                name: '',
                href: ''
            }
        })
    }

    deleteLink = e => {
        e.preventDefault()
        let [tab, category, idx] = e.target.name.split('_')

        let { work_links, personal_links } = this.state
        let cat_index

        if (tab === 'work') {
            cat_index = work_links.findIndex(x => x.category === category);
            work_links[cat_index].links.splice(idx,1)
            this.setState({
                work_links
            }, () => {
                this.saveLinks()
            })
        } else {
            cat_index = personal_links.findIndex(x => x.category === category);
            personal_links[cat_index].links.splice(idx,1)
            this.setState({
                personal_links
            }, () => {
                this.saveLinks()
            })
        }

    }

    render() {
        let {work_links, personal_links} = this.state

        const workLinkCards = work_links.map((i, idx) => (
            <LinkSection 
                key={`work_${idx}`} 
                main_cat = 'work'
                category={i.category} 
                links={i.links} 
                openNewLinkModal = {this.openAddLinkModal}
                deleteLink = {this.deleteLink}
            />
        ))

        const personalLinkCards = personal_links.map((i, idx) => (
            <LinkSection 
                key={`personal_${idx}`} 
                main_cat='personal'
                category={i.category} 
                links={i.links} 
                openNewLinkModal={this.openAddLinkModal}
                deleteLink = {this.deleteLink}
            />
        ))

        let { activeTab } = this.state

        return (
            <div className={`col-lg-8 ${this.props.sm_visible ? '': 'd-none'} d-lg-block `}>
            <div 
                className="px-3 py-3 rounded-4 border-brand-light-blue bg-brand-light-blue font-secondary"
                id='linkSection'>
                <h4 className="text-center">Useful Links</h4>
                < Nav tabs className="mt-md-5 mt-2 nav-justified" >
                    <NavItem>
                        <NavLink
                            className={activeTab === 'work' ? 'active': ''}
                            onClick={this.selectTab}
                            name="work"
                        >
                            Work
                                </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === 'personal' ? 'active': ''}
                            onClick={this.selectTab}
                            name="personal"
                        >
                            Personal
                                </NavLink>
                    </NavItem>
                </Nav>
                <TabContent className="linkTab" activeTab={activeTab}>
                    <TabPane tabId="work">
                        {workLinkCards}
                        <div className="row">
                            <Button 
                                className="w-50 mt-3 mx-auto rounded-4"
                                name="work"
                                onClick = {this.openAddSectionModal}
                            >
                                + Add Section
                            </Button>
                        </div>
                        
                    </TabPane>
                    <TabPane tabId="personal">
                            {personalLinkCards}
                            <div className="row">
                                <Button
                                    className="w-50 mt-3 mx-auto rounded-4"
                                    name="personal"
                                    onClick={this.openAddSectionModal}
                                >
                                    + Add Section
                                </Button>
                            </div>
                    </TabPane>
                </TabContent>
                <AddSectionModal 
                    open={this.state.addSectionModalOpen} 
                    value = {this.state.newSection}
                    toggle={this.closeAddSectionModal}
                    onInput = {this.handleAddSectionInput}
                    onSubmit={this.handleAddSectionSubmit}
                />
                <AddLinkModal
                    open={this.state.addLinkModalOpen}
                    value={this.state.newLink}
                    toggle={this.closeAddLinkModal}
                    onInput={this.handleAddLinkInput}
                    onSubmit={this.handleAddLinkSubmit}
                />
            </div>
            </div>
        )
    }
}


export default connect(
    null, {setUser, displayLoader,addLogEvent })(Links);