import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
    name:'notification',
    initialState: {
        status:false,
        type:'error',
        msg:''
    },
    reducers: {
        addErrorNotification: (state, action) => {
            state.status = true
            state.type = 'error'
            state.msg = action.payload
        },
        removeNotification: (state) => {
            state.status = false
            state.type = 'error'
            state.msg = ''
        }
    }
})

export const {addErrorNotification, removeNotification} = notificationSlice.actions
export default notificationSlice.reducer