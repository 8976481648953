import { UPDATE_LOADING } from "../actionTypes";

const loadingReducer =  (state = { status: false }, action) => {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default loadingReducer
