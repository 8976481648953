import React, {Component} from "react";
import {connect} from "react-redux";
import {Auth} from 'aws-amplify';
import {setUser, setAuthorizationToken} from '../auth/authSlice';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './homepage.css'


import HomepageNoAuth from "./Homepage_no_auth";
import HomepageAuth from "./Homepage_auth";

class Homepage extends Component {
    componentDidMount(){
        Auth.currentAuthenticatedUser()
                .then(async user => {
                    const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                    const { attributes } = user;
                    this.props.setUser(attributes)
                    setAuthorizationToken(jwt);
                })
                .catch(err => {console.log(err)});
      }

    render() {
        return(
            <div className="">
                {this.props.currentUser.authenticated === false &&
                    <HomepageNoAuth />
                }
                {this.props.currentUser.authenticated === true &&
                    <HomepageAuth />
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    //console.log(state)
    return {
        currentUser: state.auth
    };
  }
  
  export default connect(
    mapStateToProps, 
    { 
        setUser
    }
  )(Homepage)