import React, { Component } from "react";
import NewsTicker from "../newsticker/NewsTicker";
import Todo from "../todo/Todo";
import Links from "../links/Links";

class HomepageAuth extends Component {
    constructor(props){
        super(props)
        this.state = {
            sm_select: 'todos',
        }
        this.update_sm_vis = this.update_sm_vis.bind(this)
    }

    update_sm_vis = e => {
        console.log(e.target.name)
        this.setState({
            sm_select:e.target.name
        })
    }

    render() {
        return (
            <div className="pt-3 pt-lg-0">
                <div className="row d-lg-none">
                    <div className="col-6 px-2">
                        <button 
                            className="btn btn-primary w-100"
                            name='todos'
                            onClick={this.update_sm_vis}
                            >Todos</button>
                    </div>
                    <div className="col-6 px-2">
                        <button 
                            className="btn btn-primary w-100"
                            name='links'
                            onClick={this.update_sm_vis}
                            >Links</button>
                    </div>
                    
                </div>
                <div className="row pt-3">
                    <Todo sm_visible={this.state.sm_select === 'todos' ? true : false}/>
                    <Links sm_visible={this.state.sm_select === 'links' ? true : false}/>
                </div>
                
                <NewsTicker />
            </div>
        )
    }

    
}

export default HomepageAuth