import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
//import { apiCall } from '../../services/api';
const dt_format = 'YYYY-MM-DD HH:mm'

export const loggingSlice = createSlice({
    name:'logs',
    initialState: {
        events:[
            {
                eventType: 'initialise',
                dateTime: moment().format(dt_format),
                status: 'success',
                details:'initialised'
                
            }
        ]
    },
    reducers: {
        addLogEvent: (state, action) => {
            var event = action.payload
            event.dateTime = moment().format(dt_format)
            state.events = [...state.events, event]
            // console.log(state.events)
        }
    }
})

export const {addLogEvent} = loggingSlice.actions
export default loggingSlice.reducer