import React from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LinkCard = (props) => {
    const {
        name,
        desc,
        url,
        deleteLink
    } = props


    return (
        <div className="col-md-4 col-sm-6 mt-3">
            <div className="link-card rounded-5">
            <a 
                className="text-center bg-white rounded-5 border-white font-primary d-flex text-decoration-none"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
            >
                <p className="mx-auto my-2 fw-bold">{desc}</p>
                <button 
                className="badge text-white bg-danger link-delete d-flex align-items-center justify-content-center"
                name = {`${name}`}
                onClick={deleteLink}
            >X</button>
            </a>
            </div>
        </div>
    )
}

export default LinkCard