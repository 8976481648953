import React, { Component } from "react";
import { connect } from "react-redux";
import {Auth} from 'aws-amplify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link , Navigate} from "react-router-dom";

import {setUser, setAuthorizationToken} from './authSlice';

class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username:'',
            password:'',
            redirect:false
        }
        this.handleInput = this.handleInput.bind(this);
        this.signIn = this.signIn.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                const { attributes } = user;
                this.props.setUser(attributes)
                setAuthorizationToken(jwt);
                this.setState({
                    redirect:true
                })
            })
            .catch(err => {
                this.setState({
                    redirect: false
                })
            });
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    async signIn (e) {
        e.preventDefault();
        let {username, password} = this.state;

        try {
            const user = await Auth.signIn(username, password);
            const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
            const { attributes } = user;
            this.props.setUser(attributes)
            setAuthorizationToken(jwt);
            this.setState({
                redirect:true
            })
        } catch (error) {
            console.log('error signing in', error);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to="/" push={true} />;
        }
        return (
            <div className="text-center mt-md-5 mt-2 auth-form">
                <h1>Sign In Below</h1>
                <hr />
                <div className="text-center mt-md-5 mt-2">
                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text bg-brand-blue-gradient">
                                    <FontAwesomeIcon icon={['fas', 'paper-plane']} />
                                </span>
                            </div>
                            <input
                                type="email"
                                className="form-control text-center"
                                name="username"
                                placeholder="Email Address"
                                required="required"
                                value={this.state.username}
                                onChange={this.handleInput}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text bg-brand-blue-gradient">
                                    <FontAwesomeIcon icon={['fas', 'lock']} />
                                </span>
                            </div>
                            <input
                                type="password"
                                className="form-control text-center"
                                name="password"
                                placeholder="Password"
                                required="required"
                                value={this.state.password}
                                onChange={this.handleInput}
                            />
                        </div>
                        
                    </div>
                    <button 
                        className="btn btn-primary btn-lg bg-brand-blue-gradient mx-auto"
                        onClick={this.signIn}
                    >Sign In</button>
                    <p className="mt-1"><Link to="/auth/password-reset">Forgot Password?</Link></p>
                    <hr />
                    <p>No account? No problem. <Link to="/auth/signup">Sign up here</Link></p>
                </div>
            </div>
        );
    }
}


export default connect(
    null, 
    { 
        setUser,
    }
)(SignIn)