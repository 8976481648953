import React, {Component} from "react";
import {connect} from "react-redux";
import {Auth} from 'aws-amplify';
import { Link } from "react-router-dom";
import {setUser, setAuthorizationToken} from '../auth/authSlice';
import { addErrorNotification } from "../notifications/notificationSlice";
import {apiCall} from "../../services/api";
import { displayLoader } from "../../store/actions/loading";

class Profile extends Component {
    constructor (props) {
        super(props)
        this.state = {
            weatherLocation: '',
            newsFeed: 'bbc_news'
        }
        this.handleInput = this.handleInput.bind(this)
        this.saveProfile = this.saveProfile.bind(this)
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser()
        .then(async user => {
            const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
            const { attributes } = user;
            this.props.setUser(attributes)
            setAuthorizationToken(jwt);
        })
        .catch(err => {console.log(err)});

        this.props.displayLoader(true)
        apiCall('get','/profile').then(
            data => {
                this.setState(data)
                this.props.displayLoader(false)
            }
        ).catch(err => {
            this.props.addErrorNotification(
                `Profile API failed - please see console for more information`
            )
            console.log(err)
            this.props.displayLoader(false)
        })
    }

    componentWillUnmount() {
        this.saveProfile()
    }

    saveProfile = () => {
        let profile = this.state
        apiCall('post', '/profile', profile)
    }

    handleInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        }) 
    }

    render() {
        return (
            <div className="">
                <div className="mt-3">
                    <h6>Weather Link</h6>
                    <div className="input-group">
                        <input type="text" className={`form-control offset-1`} 
                            value={this.state.weatherLocation}
                            name='weatherLocation'
                            onChange={this.handleInput}
                            onBlur = {this.saveProfile}
                        />
                    </div>
                </div>
                <div className="mt-3">
                    <h6>Newsfeed Source</h6>
                    <div className="input-group">
                        <select type="text" className={`form-select offset-1`} 
                            value={this.state.newsFeed}
                            name='newsFeed'
                            onChange={this.handleInput}
                            onblur = {this.saveProfile}
                        >
                            <option value='bbc_news'>BBC News</option>
                            <option value='nytimes_us_news'>NY Times - US News</option>
                            <option value='nytimes_world_news'>NY Times - World News</option>
                        </select>
                    </div>
                </div>
                <div className="mt-3">
                    <Link to="/logs">Audit Logs</Link>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.auth
    };
  }
  
  export default connect(
    mapStateToProps, 
    { 
        setUser,
        displayLoader,
        addErrorNotification
    }
  )(Profile)