import { createSlice } from '@reduxjs/toolkit';
import { apiCall } from '../../services/api';

export const configSlice = createSlice({
    name:'config',
    initialState: {
        weatherLocation:'',
    },
    reducers: {
        updateLocation: (state, action) => {
            state.weatherLocation = action.payload
            let data = {
                weatherLocation: state.weatherLocation,
            }
            apiCall(
                'post', '/config', data
            )
        }
    }
})

export const {updateLocation} = configSlice.actions
export default configSlice.reducer