const settings = {
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_WEBCLIENTID,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_SRP_AUTH',
    }
}

export default settings