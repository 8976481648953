import React, { Component} from 'react';
import { connect } from "react-redux";
import EventCard from "./EventCard"

class Logs extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        let events = this.props.logs.events.map((e,idx) => (
            <EventCard key={`log_${idx}`} event={e} idx={idx}/>
        ))

        
        return(
            <div>
                <h1>Event Logs</h1>
                <div 
                    className='accordion accordion-flush'
                    id="logAccordion">
                    {events}
                </div>
                
            </div>
        )
    }
}


function mapStateToProps(state) {
  return {
      logs: state.logging
  };
}

export default connect(
    mapStateToProps
) (Logs)