import React, { Component } from "react";
import { connect } from "react-redux";

import { removeNotification } from "./notificationSlice";

import './notifications.css'

class Notification extends Component {
    constructor(props){
        super(props)
        this.closeNotification = this.closeNotification.bind(this)
    }

    closeNotification = (e) => {
      this.props.removeNotification()
      
    }

    render() {
        const {status, msg} = this.props.notification
        if (status) {
            return (
              <div className="container">
                <div className="alert alert-danger alert-dismissible fade show notification" role="alert">
                    {msg}
                    <button 
                        type="button" 
                        className="btn-close" 
                        aria-label="Close" 
                        onClick={this.closeNotification}/>
                </div>
              </div>
            )
          }
        
          return (<div />)
    }
    
  }
  
  function mapStateToProps(state) {
    return {
      notification: state.notification
    };
  }
  
  export default connect(mapStateToProps, {removeNotification})(Notification);