import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

const AddLinkModal = (props) => {
    const {
        open,
        toggle,
        value,
        onInput,
        onSubmit
    } = props

    return (
        <Modal isOpen={open} toggle={toggle} >
            <ModalHeader toggle={toggle}>Add new link</ModalHeader>
            <ModalBody>
                <div className="form-group row d-flex h-100 pr-3 mt-3" >
                    <label
                        htmlFor="newLinkTitle"
                        className="col-md-3 text-center text-md-left align-self-center mb-0" >
                        Link title</label>
                    <input
                        type='text'
                        value={value.name}
                        onChange={onInput}
                        name='name'
                        id='newLinkTitle'
                        className="col-md-9 form-control text-center" />
                </div>
                <div className="form-group row d-flex h-100 pr-3 mt-3" >
                    <label
                        htmlFor="newLinkHref"
                        className="col-md-3 text-center text-md-left align-self-center mb-0" >
                        Link url</label>
                    <input
                        type='text'
                        value={value.href}
                        onChange={onInput}
                        name='href'
                        id='newLinkHref'
                        className="col-md-9 form-control text-center" />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onSubmit}>Add</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

    export default AddLinkModal