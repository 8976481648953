import React, {Component} from "react";
import {connect} from "react-redux";
// import moment from 'moment';
import {apiCall} from "../../services/api";
import {addErrorNotification} from "../notifications/notificationSlice";
import {addLogEvent} from "../logging/loggingSlice";
import './newsticker.css'

class NewsTicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newsFeed: []
        }
        this.updateNews = this.updateNews.bind(this)
    }

    async componentDidMount() {
        await this.updateNews()

        setInterval(
            () => this.updateNews(),
            900000 //15 mins refresh
          );
    }

    async updateNews() {
        apiCall('get','/newsfeed')
            .then(data => {
                this.props.addLogEvent({
                  eventType: 'GetNewsFeed',
                  status: 'success',
                  details: '',
                })
                
                this.setState({
                    newsFeed: data.slice(0,15)
                })
            }).catch(err => {
                console.log(err)
                this.props.addLogEvent({
                  eventType: 'GetNewsFeed',
                  status: 'error',
                  details: err.message,
                })
                this.props.addErrorNotification(
                      `Newsticker API call failed - please see console for more information`
                    )
                this.setState({
                    newsFeed: [
                        {title:'Connection Lost', link: '#'}
                    ]
                })
            })
    }

    render() {
        let news = this.state.newsFeed.map((i, idx) => (
            <div 
                key={idx}
                className="ticker__item">
                    <a
                        className="font-secondary text-decoration-none"
                        href={i.link}
                        target="_blank"
                        rel="noopener noreferrer">
                            {i.title}
                    </a>
            </div>
        ))

        return (
            <div className="ticker-wrap">
                <div className="ticker">
                    <div className="ticker__item">
                        <a
                            className="font-secondary text-decoration-none"
                            href='https://bbc.co.uk'
                            target="_blank"
                            rel="noopener noreferrer">
                                Latest News:
                        </a>
                    </div>
                    {news}
                </div>
            </div>
        )
    }
}

export default connect(null, {addErrorNotification, addLogEvent})(NewsTicker)