import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {Link} from "react-router-dom";

const WeatherCard = (props) => {
    const weatherIconMap = {
        'Sunny': 'sun',
        'Sunny Intervals': 'cloud-sun',
        'Partly Cloudy': 'cloud-sun',
        'Light Cloud': 'cloud',
        'Thick Cloud': 'cloud', 
        'Drizzle': 'cloud-rain',
        'Light Rain Showers': 'cloud-rain',
        'Light Rain': 'cloud-rain',
        'Heavy Rain': 'cloud-showers-heavy',
        'Thundery Showers': 'cloud-bolt',
        'Fog': 'smog',
        //faSnowflake,
    }

    let [desc, min, max] = props.weather.split(': ').slice(1)
    let [headline] = desc.split(', ')
    
    min = min.split(' (')[0]
    if (max !== undefined) {
        max = max.split(' (')[0]
    } else {
        max = "N/A"
    }
    
    let weatherIcon = <FontAwesomeIcon icon={['fas', 'search']}/>

    if (weatherIconMap[headline]!== undefined){
        weatherIcon = <FontAwesomeIcon icon={['fas', weatherIconMap[headline]]}/>
    } else {
        console.log(headline)
    }


    return (
        <a
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
            className='my-auto nav-link d-none d-lg-block'
        >
            <div className="border-w2-r6 mx-2 mt-3">
                <div className="row ml-2">
                    <div className="col-4 text-center align-middle font-2">
                        {weatherIcon}
                    </div>
                    <div className="col-8">
                        <p>
                            Min: {min} <br />
                            Max: {max}
                        </p>
                    </div>
                </div>
            </div>
        </a>
        
    )
}

export default WeatherCard