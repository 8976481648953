import React, { Component} from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavbarBrand,
    NavbarText
} from 'reactstrap';
import { Link } from "react-router-dom";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {setUser, logOut, setAuthorizationToken} from '../auth/authSlice';
import {updateLocation} from "../config/configSlice";
import {addLogEvent} from "../logging/loggingSlice";
import { connect } from "react-redux";
import {Auth} from 'aws-amplify';
import NavLogo from '../../images/logo.png';
import './navbar.css'
import { apiCall } from '../../services/api';
import WeatherCard from './WeatherCard';

class HomeNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      date: moment().format('ddd Do MMM YY - HH:mm'),
      weather:[]
    }
    this.toggle = this.toggle.bind(this);
    this.signout = this.signout.bind(this);
    this.getWeather = this.getWeather.bind(this);
  }

  componentDidMount(){
    setInterval(
      () => this.setState({
        date: moment().format('ddd Do MMM YY - HH:mm')
      }),
      1000
    );
    Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                const { attributes } = user;
                this.props.setUser(attributes)
                setAuthorizationToken(jwt);

                this.getWeather()
                
                setInterval(
                  () => this.getWeather(), 900000
                )

            })
            .catch(err => {console.log(err)});
  }
  
  getWeather() {
    apiCall('get','/weather').then(
      data => {
        this.props.addLogEvent({
          eventType: 'GetWeather',
          status: 'success',
          details: '',
        })
        this.setState({
          weather:data
        })
      }).catch(err => {
        console.log(err)
        this.props.addLogEvent({
          eventType: 'GetWeather',
          status: 'error',
          details: err.message,
        })
      })
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };

  async signout() {
    try {
      await Auth.signOut();
      this.props.logOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }
    
    
 

    render() {
      return (
        <div>
          <Navbar
            className="home-navbar"
            dark
            expand="md"
            container="md"
        >
            <NavbarBrand>
              <Link to="/">
                <img src={NavLogo} alt="Daily Homepage" style={{ height: 65 }} />
              </Link>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              
              {this.props.currentUser.authenticated === false &&
              <Nav className="me-auto" navbar>
                <NavItem>
                  <Link to="/auth/signup" className='nav-link'>
                      <FontAwesomeIcon icon={['fas', 'user-plus']} /> Sign-up
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/auth/signin" className='nav-link'>
                      <FontAwesomeIcon icon={['fas', 'sign-in-alt']} /> Login 
                  </Link>
                </NavItem>
                </Nav>
              }

              {this.props.currentUser.authenticated === true &&
              <NavbarText className="me-auto">
                <h2>Welcome Back {this.props.currentUser.currentUser.given_name}</h2>
              </NavbarText>
              }
              
              <NavbarText>
                <h2>{this.state.date}</h2>
              </NavbarText>
              {this.props.currentUser.authenticated === true &&
              <Nav navbar className='d-flex align-items-center'>
                {this.state.weather.length > 0 && 
                  <WeatherCard weather = {this.state.weather[0].title} link={this.state.weather[0].link} />
                }
                
                <li className='nav-item dropdown'>
                  <a 
                    href='/#'
                    className='nav-link dropdown-toggle'
                    role="button" 
                    data-bs-toggle="dropdown" 
                    aria-expanded="false">
                      Menu
                  </a>
                      <ul className="dropdown-menu font-primary">
                        <li>
                          <Link to={'/profile'} className="nav-link font-primary">Profile</Link>  
                        </li>
                        <li>
                          <Link onClick={this.signout} className='nav-link font-primary'>
                            <FontAwesomeIcon icon={['fas', 'sign-out-alt']} /> Sign out
                          </Link>
                        </li>
                      </ul>
                </li>
                <NavItem>
                  
                </NavItem>
              </Nav>
              }
            </Collapse>
          </Navbar>
        </div>
    )};
}

function mapStateToProps(state) {
  return {
      currentUser: state.auth
  };
}

export default connect(
  mapStateToProps, 
  { 
      setUser,
      logOut,
      updateLocation,
      addLogEvent
  }
)(HomeNavBar)