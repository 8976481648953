import { createSlice } from '@reduxjs/toolkit';
import { 
    // apiCall, 
    setTokenHeader 
  } from "../../services/api";

export const authSlice = createSlice({
    name:'auth',
    initialState:{
        authenticated: false,
        currentUser: {}
    },
    reducers:{
        setUser: (state, action) => {
            state.currentUser = action.payload
            state.authenticated = !!Object.keys(action.payload).length
        },
        logOut: (state, action) => {
            state.currentUser = {}
            state.authenticated = false
        }
    }
})

export function setAuthorizationToken(token) {
    setTokenHeader(token);
}

export const { setUser, logOut} = authSlice.actions

export default authSlice.reducer