import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
//import configReducer from '../features/config/configSlice';
import notificationReducer from '../features/notifications/notificationSlice';
import loggingReducer from '../features/logging/loggingSlice';
import loading from './reducers/loading';

export default configureStore({
  reducer: {
    auth: authReducer,
    //config: configReducer,
    notification: notificationReducer,
    loading:loading,
    logging: loggingReducer
  }
})