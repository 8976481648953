import React from "react";

const EventCard = (props) => {
    const {
        event,
        idx
    } = props
    
    return (
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={`#collapse_${idx}`}
                    aria-expanded="false" 
                    aria-controls={`collapse_${idx}`}>
                {`${event.dateTime}: ${event.eventType} - ${event.status}`}
              </button>
            </h2>
            <div id={`collapse_${idx}`}
            className="accordion-collapse collapse" 
            data-bs-parent="#logAccordion">
              <div className="accordion-body">
                {event.details}
              </div>
            </div>
          </div>
    )
}


export default EventCard