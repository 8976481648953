import React from 'react';
import {Route, Routes} from "react-router-dom";

import Notification from "./features/notifications/Notifications";
import Homepage from "./features/homepage/Homepage";
import AuthRoutes from "./features/auth/AuthRoutes";
import SignIn from "./features/auth/SignIn";
import SignUp from "./features/auth/SignUp";
import Profile from "./features/profile/Profile";
import Logs from "./features/logging/Logs";

import './App.css'


function DailyRoutes () {
    return (
        <div className="routeContainer">
            <Notification />
            <div className="container">
                <Routes>
                    {/* Index Route*/ } 
                    <Route index element={<Homepage />} />
                    <Route path="/" element={<Homepage />} />

                    {/* Auth Routes */}
                    <Route path="/auth" element={<AuthRoutes />}>
                        <Route index element={<SignIn />} />
                        <Route path="signin" element={<SignIn />} />
                        <Route path="signup" element={<SignUp />} />
                    </Route>

                    <Route path="/profile" element={<Profile />} />
                    
                    <Route path="/logs" element={<Logs />} />
                </Routes>
            </div>
        </div>
    )
}

export default DailyRoutes