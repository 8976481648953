import React from "react";
import LinkCard from "./LinkCard";
import {
    Button
} from 'reactstrap';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LinkSection = (props) => {
    const {
        main_cat,
        category,
        links,
        openNewLinkModal,
        deleteLink
    } = props

    const LinkCards = links.map((i, idx) => (
        <LinkCard 
        key={`${main_cat}_${category}_${idx}`} 
        name={`${main_cat}_${category}_${idx}`} 
        desc={i.name} 
        url={i.url} 
        deleteLink={deleteLink}
        />
    ))

    return (
        <div className="mt-3">
            <h6 className="mb-0">{category}</h6>
            <div className="row gx-2 px-3 mt-0">
                {LinkCards}
                <div className="col-md-4 col-sm-6 px-2 mt-3">
                    <Button
                        className="w-100 h-100 mx-auto border-w2-r6 rounded-4"
                        name={`${main_cat}_${category}`}
                        onClick={openNewLinkModal}
                    >
                        + Add Link
                            </Button>
                </div>
            </div>
        </div>
    )
}

export default LinkSection