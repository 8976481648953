import React from "react";
import {
    Outlet
} from "react-router-dom";

import './auth.css'


const AuthRoutes = props => {
    return (
        <Outlet />
    )
}


export default AuthRoutes;