import React, {Component} from "react";
import {connect} from "react-redux";
import {Auth} from 'aws-amplify';
import {setUser, setAuthorizationToken} from '../auth/authSlice';
import { addErrorNotification } from "../notifications/notificationSlice";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import './todo.css'
import { apiCall } from "../../services/api";

class Todo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newToDo:{
                desc:'',
                complete: false,
                dueDate: ''
            },
            mainGoal: {
                desc:'',
                complete: false,
                dueDate: ''
            },
            priorities_1: {
                    desc:'',
                    complete: false,
                    dueDate: ''
                },
            priorities_2: {
                    desc:'',
                    complete: false,
                    dueDate: ''
                },
            priorities_3: {
                    desc:'',
                    complete: false,
                    dueDate: ''
                },
            other_todos: []
        }
        this.handleInput = this.handleInput.bind(this)
        this.saveToDos = this.saveToDos.bind(this)
        this.toggleComplete = this.toggleComplete.bind(this)
        this.makeMainGoal = this.makeMainGoal.bind(this)
        this.deleteToDo = this.deleteToDo.bind(this)
    }

    componentDidMount(){
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                const { attributes } = user;
                this.props.setUser(attributes)
                setAuthorizationToken(jwt);
            })
            .catch(err => {console.log(err)});


        apiCall('get', '/todos').then(
            data => {
                this.setState(data)
            }
        )
        
    }

    saveToDos = () => {
        let {newToDo, ...todos} = this.state
        apiCall('post', '/todos', {todos}).then(
            data => {
                console.log(data)
            }
        ).catch(err => {
            this.props.addErrorNotification(
                `Saving ToDos failed - please see console for more information`
              )
              this.props.displayLoader(false)
        })
    }

    toggleEdit = e => {
        this.setState({
            edit: !this.state.edit
        })
    }

    handleInput = e => {
        this.setState({
            [e.target.name]:{
                desc: e.target.value,
                complete: false
            }
        }) 
    }

    toggleComplete = e => {
        if ( e.target.name.startsWith("other_")) {
            let idx = e.target.name.split('_')[1]
            idx = parseInt(idx)
            let todos = this.state.other_todos
            let updatedTask = {...todos[idx], complete: !todos[idx]['complete']}
            let updateToDos = [...todos]
            updateToDos[idx] = updatedTask
            this.setState({
                other_todos: updateToDos
            }, () => {this.saveToDos()})
        } else {
            let task = this.state[e.target.name]
            let updatedTask = { ...task, complete: !task['complete'] }

            this.setState({
                [e.target.name]: updatedTask
            }, () => {this.saveToDos()})
        }
    }

    makeMainGoal = e => {
        let task = this.state[e.target.name]

        this.setState({
            [e.target.name]: this.state.mainGoal,
            mainGoal:task
        }, () => {this.saveToDos()})
    }

    addNewToDo = e => {
        let todos = this.state.other_todos

        todos.push(this.state.newToDo)

        this.setState({
            other_todos:todos,
            newToDo:{
                desc:'',
                complete: false
            }
        }, () => {this.saveToDos()})
    }

    deleteToDo = e => {
        if ( e.target.name.startsWith("other_")) {
            let idx = e.target.name.split('_')[1]
            idx = parseInt(idx)
            let todos = this.state.other_todos
            todos.splice(idx,1)
            this.setState({
                other_todos: todos
            }, () => {this.saveToDos()})
        } else {
            let task = this.state[e.target.name]
            let updatedTask = { ...task, complete: !task['complete'] }

            this.setState({
                [e.target.name]: updatedTask
            }, () => {this.saveToDos()})
        }
    }

    render() {

        const todos = this.state.other_todos.map((i, idx) => (
            <div className="input-group" key={idx}>
                <input type="text" className={`form-control offset-1 ${i.complete && 'strikethrough'}`} 
                    value={i.desc}
                    name={`other_${idx}`}
                    readOnly
                />

                <button 
                    className="btn btn-outline-secondary dropdown-toggle" 
                    type="button" data-bs-toggle="dropdown" 
                    aria-expanded="false"
                    ></button>
                <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                        <button 
                            type="button" 
                            className="dropdown-item"
                            name={`other_${idx}`}
                            onClick={this.toggleComplete}
                        >Toggle Complete</button>
                    </li>
                     <li><button 
                            type="button" 
                            className="dropdown-item"
                            name={`other_${idx}`}
                            onClick={this.deleteToDo}
                        >Delete</button></li>
                    {/* <li><button 
                            type="button" 
                            className="dropdown-item"
                            name={`other_${idx}`}
                            onClick={this.makeMainGoal}
                        >Make Main Goal</button></li> */}
                </ul>
            </div>
        ))

        return(
        <div className={`col-lg-4 ${this.props.sm_visible ? '': 'd-none'} d-lg-block `}>
        <div className="todo-panel rounded-4">
                <div className="mt-3">
                    <h6>Today's Main Goal</h6>
                    <div className="input-group">
                        <input type="text" className={`form-control offset-1 ${this.state.mainGoal.complete && 'strikethrough'}`} 
                            value={this.state.mainGoal.desc}
                            name='mainGoal'
                            onChange={this.handleInput}
                            onBlur = {this.saveToDos}
                        />
                        <button 
                            type="button" 
                            className="btn btn-outline-success"
                            name='mainGoal'
                            onClick={this.toggleComplete}
                        >Complete</button>
                    </div>
                </div>
                <div className="mt-3">
                    <h6>Today's Priorities</h6>
                    <div className="input-group">
                        <input type="text" className={`form-control offset-1 ${this.state.priorities_1.complete && 'strikethrough'}`} 
                            value={this.state.priorities_1.desc}
                            name='priorities_1'
                            onChange={this.handleInput}
                            onBlur = {this.saveToDos}
                        />

                        <button 
                            className="btn btn-outline-secondary dropdown-toggle" 
                            type="button" data-bs-toggle="dropdown" 
                            aria-expanded="false"
                            ></button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <button 
                                    type="button" 
                                    className="dropdown-item"
                                    name='priorities_1'
                                    onClick={this.toggleComplete}
                                >Toggle Complete</button>
                            </li>
                            <li><button 
                                    type="button" 
                                    className="dropdown-item"
                                    name='priorities_1'
                                    onClick={this.makeMainGoal}
                                >Make Main Goal</button></li>
                        </ul>
                    </div>
                    <div className="input-group">
                        <input type="text" className={`form-control offset-1 ${this.state.priorities_2.complete && 'strikethrough'}`} 
                            value={this.state.priorities_2.desc}
                            name='priorities_2'
                            onChange={this.handleInput}
                            onBlur = {this.saveToDos}
                        />
                        <button 
                            className="btn btn-outline-secondary dropdown-toggle" 
                            type="button" data-bs-toggle="dropdown" 
                            aria-expanded="false"
                            ></button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <button 
                                    type="button" 
                                    className="dropdown-item"
                                    name='priorities_2'
                                    onClick={this.toggleComplete}
                                >Toggle Complete</button>
                            </li>
                            <li><button 
                                    type="button" 
                                    className="dropdown-item"
                                    name='priorities_2'
                                    onClick={this.makeMainGoal}
                                >Make Main Goal</button></li>
                        </ul>
                    </div>
                    <div className="input-group">
                        <input type="text" className={`form-control offset-1 ${this.state.priorities_3.complete && 'strikethrough'}`} 
                            value={this.state.priorities_3.desc}
                            name='priorities_3'
                            onChange={this.handleInput}
                            onBlur = {this.saveToDos}
                        />
                        <button 
                            className="btn btn-outline-secondary dropdown-toggle" 
                            type="button" data-bs-toggle="dropdown" 
                            aria-expanded="false"
                            ></button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                                <button 
                                    type="button" 
                                    className="dropdown-item"
                                    name='priorities_3'
                                    onClick={this.toggleComplete}
                                >Toggle Complete</button>
                            </li>
                            <li><button 
                                    type="button" 
                                    className="dropdown-item"
                                    name='priorities_3'
                                    onClick={this.makeMainGoal}
                                >Make Main Goal</button></li>
                        </ul>
                    </div>
                </div>
                <div className="mt-3">
                    <h6>Today's ToDo List</h6>
                    <div className="input-group">
                        <div className="input-group">
                            <input type="text" className={`form-control offset-1`} 
                                value={this.state.newToDo.desc}
                                name='newToDo'
                                onChange={this.handleInput}
                            />
                            <button 
                                type="button" 
                                className="btn btn-outline-success"
                                onClick={this.addNewToDo}
                            >Add New</button>
                        </div>
                    </div> 
                    
                    
                </div>
                <div id='otherToDo'>
                        {todos}
                </div>
            </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        currentUser: state.auth
    };
  }
  
  export default connect(
    mapStateToProps, 
    { 
        setUser,
        addErrorNotification
    }
  )(Todo)