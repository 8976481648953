import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {Amplify} from 'aws-amplify';
import awsConfig from './aws-exports';
// import { Container } from 'reactstrap';

// Configure Redux store
import store from './store'
import { Provider } from 'react-redux'

import DailyRoutes from './DailyRoutes';

// General Page components
//import Notification from "./Notifications";
import Navbar from './features/navbar/Navbar';
import Loader from "./features/loading/Loading";


// FontAwesome
require("./services/fontAwesome")

// Configure AWS
Amplify.configure(awsConfig);

function App() {
  return (
    <Provider store = {store} >
      <Router>
      <div className="App">
        {/* <Notification /> */}
        <Navbar />
        {/* <Container> */}

          <DailyRoutes />
        {/* </Container> */}
        {/*Loading Spinner*/}
        <Loader />
      </div>
      </Router>
    </Provider>
  );
}

export default App;
