import React from "react";

export default function HomepageNoAuth() {
    return (
        <div className="row pt-4">
            <div className="col-sm-8">
                <h1>Welcome to Daily Homepage</h1>
                        
                <p>
                    Daily Homepage is designed to be your onestop 
                    landing page to aid your productivity.
                </p>
                <p>
                    It includes the functionality to:
                </p>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                        Set up your common sites, grouped by activity, 
                        type etc
                    </li>
                    <li className="list-group-item">
                        Get a three day weather forecast for a location 
                        of your choice
                    </li>
                    <li className="list-group-item">
                        Get the latest top news stories from BBC News
                    </li>
                    <li className="list-group-item">
                        Create an activity journal with todo items grouped 
                        by dominant task, key priorities, and others
                    </li>
                </ul>
            </div>
            <div className="col-sm-4">
                <div className="home-img-container">
                    
                    <div className="home-img-fh">
                    Photo by <a href="https://unsplash.com/@lilartsy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">lilartsy</a> on <a href="https://unsplash.com/s/photos/journal?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                    </div>
                </div>
            </div>
        </div>
    )
}